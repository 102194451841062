@import 'mixins'; 

$bg-color: #ccc;
$text: #000;
$placeholder: #3e3e3e;
$footer-bg: #110d27;
$borders: #110d27;
$border: #cccccc;
$error: #a50000;

@font-face {
    font-family: 'CormorantGaramond-Regular';
    src: url('../fonts/CormorantGaramond-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito-ExtraLight';
    src: url('../fonts/Nunito-ExtraLight.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito-Regular';
    src: url('../fonts/Nunito-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

// * {
//     border: 1px solid red;
// }

html {
    overflow-y: scroll;
}

html,
body {
    margin: 0 auto;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
}

h1 {
    font-family: 'CormorantGaramond-Regular'!important;
    font-size: 40px!important;
    line-height: 42px!important;
    letter-spacing: 2px!important;
}

p,
.p {
    font-family: 'Nunito-ExtraLight';
    font-size: 18px!important;
    line-height: 20px;
    white-space: pre-line;
    letter-spacing: 1px!important;
}

.p-medium {
    font-size: 16px;
    font-family: 'Nunito-ExtraLight';
}


.p-small {
    font-size: 14px;
    font-family: 'Nunito-ExtraLight';
}

.wrapper {
    min-height: 100vh;
    padding-bottom: 300px;
    position:relative;
    min-width: 350px;

    @include lg {
        padding-bottom: 100px;
    }
}

.content-wrapper {
    padding-top: 50px;

    @include lg {
        padding-top: 70px;
    }
}

//Header 
.header {
    height: 50px;
    position: fixed;
    width: 100%;
    padding: 10px 10px;
    min-width: 350px;
    margin: 0;
    z-index: 999;

    @include lg {
        height: 70px;
    }

    .menuIcon {
        font-size: 25px;
        color: white;
    }

    .accountIcon {
        font-size: 25px;
        color: white;
    }

    .logo{
        &.mobile {
            height: 30px;
            width: auto;
        }
        &.desktop {
            height: 45px;
            width: auto;
        }
    }
}

.header-bg {
    background: red;
    position: fixed;
    width: 100%;
    height: 50px;

    @include lg {
        height: 70px;
    }
    z-index: 1;
}

.header-bg {
    .header {
        background: $bg-color!important;
    }
}

//Footer
footer {
    background: $footer-bg;
    width: 100%;
    padding: 15px;
    color: white;
    position:absolute;
    bottom:0;
    font-family: 'Nunito-ExtraLight';
    font-size: 16px;
    min-width: 350px;
    letter-spacing: 1px!important;

    .instagramIcon {
        font-size: 35px;
        color: white;
    }

    a,
    a:hover {
        color: white;
    }

}

//General
.loader {
    background: white;
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 999;
}

.coverImage {
    height: 87vh;
    // height: 20vh;
    width: 100%;
    position: relative;
    z-index: 2;
    background: $bg-color;
    background-size: cover!important;
    background-position: center!important;
    background-repeat: no-repeat;
}

.subtitle {
    position: absolute;
    width: 100%;
    top: 70px;
    z-index: 5;
    font-size: 25px;
    color: white;
    font-family: 'Nunito-ExtraLight';
    text-align: center;
    letter-spacing: 2px;

    @include lg {
        top: 100px;
    }
}

.slogan {
    position: absolute;
    width: 100%;
    bottom: 10px;
    z-index: 5;
    font-size: 20px;
    color: white;
    font-family: 'Nunito-ExtraLight';
    text-align: center;
    letter-spacing: 2px;
}

.arrowDownIcon {
    width: auto;
    height: 20px;
    margin-top: 10px;

    &:hover {
        cursor: pointer;
    }
}

.arrowUpIcon {
    width: auto;
    height: 30px;

    &:hover {
        cursor: pointer;
    }
}

//Fields

.input-wrapper {
    position: relative;

    label {
        position: absolute;
        top: 15px;
        left: 15px;
        color: $placeholder;
        font-size: 18px;
        pointer-events: none;
        font-family: 'Nunito-Regular';
    }
    
    .input {
        border: 1px solid $borders;
        height: 50px;
        width: 100%;
        margin-bottom: 10px;
        padding: 13px 15px 0 15px;
        transition: .1s;
        font-family: 'Nunito-Regular';
        font-size: 18px;

        &:focus {
            outline: 0;
        }
        
        &:not(:placeholder-shown) + label,
        &:focus + label {
            top: 5px;
            font-size: 12px;
            transition: .1s;
        }
    }

    &.error {
        input {
            border: 1px solid $error;
        }
    }
}

.button {
    height: 50px;
    width: 100%;
    text-decoration: none!important;
    font-family: 'Nunito-Regular';
    font-size: 18px;

    &:hover {
        opacity: .8;
    }
}

.form-header {
    font-family: 'CormorantGaramond-Regular'!important;
    font-size: 43px;
    line-height: 45px;
    white-space: pre-line;

    @include lg {
        font-size: 50px;
        line-height: 50px;
    }
}

.border-form {
    @include lg {
        border-left: 3px solid $border!important;
    }
}

.error {
    color: $error;
}

.cat-block {
    width: 130px;
    height: 130px;
    margin-right: 10px;

    &:hover {
        cursor: pointer;
        opacity: .7;
    }

    &.selected {
        border: 2px solid $borders;
    }
}
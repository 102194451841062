$xs: "(min-width: 0px)";
$sm: "(min-width: 576px)";
$md: "(min-width: 720px)";
$lg: "(min-width: 992px)";
$xl: "(min-width: 1200px)";

@mixin xs {
  @media #{$xs}  {
    @content;
  }
}

@mixin sm {
  @media #{$sm}  {
    @content;
  }
}

@mixin md {
  @media #{$md}  {
    @content;
  }
}

@mixin lg {
  @media #{$lg}  {
    @content;
  }
}

@mixin xl {
    @media #{$xl}  {
      @content;
    }
}